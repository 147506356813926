<template>
  <div class="order-statistic">
    <div class="statistic">
      <div class="statistic-item clear-fix">
        <div class="statistic-item-block">
          <p class="block-title">营业总额(<span>元</span>)</p>
          <p class="block-number">{{(total.payMoney + today.payMoney) / 100 | F1000}}</p>
          <p class="block-rate">昨日总额: <span>{{total.payMoney / 100 | F1000}} </span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">累计制作(<span>杯</span>)</p>
          <p class="block-number">{{today.payOrders + today.notPayOrders + total.payOrders + total.notPayOrders}}</p>
          <p class="block-rate">支付制作: <i>{{today.payOrders + total.payOrders}}</i></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">设备总数(<span>台</span>)</p>
          <p class="block-number">{{deviceCnt}}</p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">支付系数(<span>=支付/非支付订单数</span>)</p>
          <p class="block-number" v-if="(today.payOrders +
            today.notPayOrders + total.payOrders + total.notPayOrders) > 0">{{((today.payOrders + total.payOrders)/ (today.payOrders +
            today.notPayOrders + total.payOrders + total.notPayOrders)) | F1000}}</p>
          <p class="block-number" v-else>0</p>
        </div>
      </div>
      <div class="statistic-item clear-fix">
        <div class="statistic-item-block">
          <p class="block-title">今日总订单 (<span>笔</span>)</p>
          <p class="block-number">{{ today.payOrders + today.notPayOrders}}</p>
          <p class="block-rate"><i>昨日订单</i>: <span>{{ yesterday.payOrders + yesterday.notPayOrders}}</span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">今日营业额(<span>元</span>)</p>
          <p class="block-number">{{today.payMoney / 100 | F1000}}</p>
          <p class="block-rate"><i>昨日营业额</i>: {{yesterday.payMoney / 100 | F1000}} <span></span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">销售订单 (<span>笔</span>)</p>
          <p class="block-number">{{today.payOrders}}</p>
          <p class="block-rate"><i>昨日销售订单</i>: <span>{{yesterday.payOrders}}</span></p>
        </div>
        <div class="statistic-item-block">
          <p class="block-title">平均销售单价(<span>元</span>)</p>
          <p class="block-number" v-if="today.payOrders >0">{{(total.payMoney/100) / today.payOrders | F1000}}</p>
          <p class="block-number" v-else>0</p>
          <p class="block-rate" >
            同比<i>昨日</i>:
            <span v-if="yesterday.payOrders >0">{{(yesterday.payMoney/100) / yesterday.payOrders | F1000}}</span>
            <span v-else>0</span>
          </p>
        </div>
      </div>
    </div>
    <el-row class="chart" :gutter="12">
      <el-col :span="18">
        <p class="chart-title">
          <span class="text">近七天制作分布</span>
        </p>
        <statistics-charts class="pay-chart" ref="statisticChart"></statistics-charts>
      </el-col>
      <el-col :span="6">
        <p class="chart-title">
          <span class="text">累积支付占比</span>
        </p>
        <statistics-pie class="pay-chart" ref="statisticPie"></statistics-pie>
      </el-col>

    </el-row>
  </div>
</template>

<script>

  import DrinkBar from '@/components/DrinkBar'
  import StatisticsCharts from './statistics_charts'
  import StatisticsPie from './statistics_pie'

  import {orderSurvey} from '@/api/collection/order'
  import {fillObj} from "@/util/checkLogin";

  export default {
    name: '',
    components: {
      DrinkBar,
      StatisticsCharts,
      StatisticsPie
    },

    mounted: function () {
      orderSurvey().then(res => {
        let data = res.data
        fillObj(this.total, data.total)
        fillObj(this.yesterday, data.yesterday)
        fillObj(this.today, data.today)
        this.deviceCnt = data.deviceCnt
        this.$refs.statisticChart.reset(data.seven || [])
        this.$refs.statisticPie.reset(this.total, this.today)
      })
    },
    data: function () {
      return {
        expand: true,
        elLoading: false,
        total: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        deviceCnt: 0,
        yesterday: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        today: {
          payOrders: 0,
          notPayOrders: 0,
          payMoney: 0,
        },
        seven: []
      }
    },
    methods: {

    },
  }
</script>

<style lang="scss" scoped>
  .order-statistic {
    padding-top: 0 !important;
    .statistic {
      display: flex;
      width: 100%;
      /*border-top: 1px solid;*/
      margin-top: $padding;
      border-bottom: 1px solid;
      padding-bottom: 12px;
      @include set_border_color($--border-color, $--border-color-dark);

      &-item {
        width: 50%;

        &-block {
          width: 40%;
          float: left;
          padding-top: 16px;
          padding-bottom: 16px;
          padding-left: 32px;

          .block-title {
            @include set_font_color($--font-02-color, $--font-color-dark);
            line-height: 22px;

            span {
              font-size: 12px;
            }
          }

          .block-number {
            @include set_font_color($--font-01-color, $--font-color-dark);
            line-height: 36px;
            font-size: 24px;
          }

          .block-rate {
            line-height: 21px;
            font-size: 12px;
            @include set_font_color($--font-02-color, $--font-color-dark);
          }
        }
      }

      .statistic-item:nth-child(odd) {
        border-right: 1px solid;
        @include set_border_color($--border-color, $--border-color-dark);
      }
    }

    .chart {
      margin-bottom: 1%;
      padding: 24px 32px;

      .chart-title {
        display: inline-block;
        vertical-align: top;
        height: 24px;
        line-height: 24px;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 16px;

        .text {
          @include set_font_color($--font-01-color, $--font-color-dark);
          font-size: 16px;
        }

        .con {
          margin-left: 16px;
        }
      }

      .pay-chart {
        height: 260px;
      }
    }

  }
</style>
