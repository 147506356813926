<template>
    <div class="drink-bar" id="drink-bar">

    </div>

</template>

<script>
    import echarts from 'echarts'
    var myColor = ['#eb2100', '#eb3600', '#d0570e', '#d0a00e', '#34da62', '#00e9db', '#00c0e9', '#0096f3', '#33CCFF', '#33FFCC'];
    export default {
        name: '',
        components: {},
        computed: {
            getTheme() {
                return this.$store.state.config.theme;
            }
        },
        watch: {
            getTheme(val) {
                this.changeThemeColor(val)
            }
        },
        mounted: function () {
            this.chart = echarts.init(document.getElementById('drink-bar'))
            this.changeThemeColor(this.getTime)
        },
        data: function () {
            return {
                chart: null,
                option: {
                    grid: {
                        left: '11%',
                        top: '12%',
                        right: '0%',
                        bottom: '8%',
                        containLabel: true
                    },
                    xAxis: [{
                        show: false,
                    }],
                    yAxis: [{
                        axisTick: 'none',
                        axisLine: 'none',
                        offset: '27',
                        axisLabel: {
                            textStyle: {
                                color: '#ffffff',
                                fontSize: '13',
                            }
                        },
                        data: ['意式咖啡', '美式咖啡', '卡布奇诺', '拿铁', '巧克力', '牛奶', '热水', '焦糖咖啡', '蓝山咖啡', '测试咖啡']
                    }, {
                        name: '饮品杯量',
                        nameGap: '50',
//                        nameTextStyle: {
//                            color: '#656565',
//                            fontSize: '16',
//                        },
                        axisLabel: {
                            textStyle: {
                                color: '#ffffff',
                                fontSize: '13',
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: 'rgba(0,0,0,0)'
                                                                                                                                       }
                        },
                        data: [],
                    }],
                    series: [{
                        name: '条',
                        type: 'bar',
                        yAxisIndex: 0,
                        data: [4, 13, 25, 29, 38, 44, 50, 52, 60, 72],
                        label: {
                            normal: {
                                show: true,
                                position: 'right',
                                textStyle: {
                                    color: '#ffffff',
                                    fontSize: '16',
                                }
                            }
                        },
                        barWidth: 12,
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var num = myColor.length;
                                    return myColor[params.dataIndex % num]
                                },
                            }
                        },
                        z: 2
                    },
                        {
                            name: '外圆',
                            type: 'scatter',
                            hoverAnimation: false,
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            yAxisIndex: 1,
                            symbolSize: 16,
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        var num = myColor.length;
                                        return myColor[params.dataIndex % num]
                                    },
                                    opacity: 1,
                                }
                            },
                            z: 2
                        }
                    ]
                }
            }
        },
        methods: {
            changeThemeColor (val) {
                if (val === 'dark') {
                    this.option.yAxis[0].axisLabel.textStyle.color = '#AAAFBA'
                    this.option.series[0].label.normal.textStyle.color = '#FFFFFF'
//                    this.option.yAxis[1].nameTextStyle.color = '#AAAFBA'
                } else {
                    this.option.yAxis[0].axisLabel.textStyle.color = '#333'
                    this.option.series[0].label.normal.textStyle.color = '#333'
//                    this.option.yAxis[1].nameTextStyle.color = '#333'
                }
                this.chart.setOption(this.option)
            },
        },
    }
</script>

<style lang="scss">
    .drink-bar{
        height: 100%;
        width: 100%;
    }
</style>
