<template>
  <div class="statistics-chart">
    <div id="fee-chart" class="fee-chart"></div>
  </div>

</template>

<script>
  import echarts from 'echarts'
  import {mapGetters} from "vuex";
  import {EleResize} from '@/assets/js/esresize.js'

  export default {
    computed: {
      ...mapGetters(['theme'])
    },
    props: {
      bills: {
        default: () => [],
        type: Array
      },
    },
    data() {
      return {
        chart: null,
        option: {
          color: ['#108EE9', '#7adbe6'],
          legend: {
            type: 'plain',
            right: 80,
            top: 10,
            textStyle: {
              color: '#131313'
            }
          },
          tooltip: {
            show: true,
            trigger: 'item',
            formatter: '{a}:{c0}杯'
          },
          grid: {
            left: '5%',
            right: 30,
            bottom: '10%'
          },
          xAxis: {
            name: '',
            type: 'category',
            data: [0],
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#e1e1e1'
              }
            },
            axisLabel: {
              color: '#131313'
            },

          },
          yAxis: {
            name: '杯',
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#131313'
              }
            },
            axisLabel: {
              color: '#131313'
            },
            axisTick: {       //y轴刻度线
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#e1e1e1',
                type: 'dashed'
              }
            }
          },
          series: [
            {
              name: '充值',
              type: 'bar',
              barGap: 0,
              barWidth: '15%',
              data: [0, 0, 0, 0, 0, 0, 0]
            },
            {
              name: '消费',
              type: 'bar',
              barGap: 0,
              barWidth: '15%',
              data: [0, 0, 0, 0, 0, 0, 0]
            },
          ]
        }
      }
    },
    methods: {
      formatTime(year, month, day) {
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        return year + '-' + month + '-' + day
      },
      changeTheme(theme) {
        let vue = this
        if (theme === `dark`) {
          vue.option.legend.textStyle.color = '#AAAFBA'
          vue.option.yAxis.splitLine.lineStyle.color = '#676f77'
          vue.option.yAxis.axisLine.lineStyle.color = '#AAAFBA'
          vue.option.xAxis.axisLabel.color = '#AAAFBA'
          vue.option.yAxis.axisLabel.color = '#AAAFBA'
        } else {
          vue.option.legend.textStyle.color = '#131313'
          vue.option.yAxis.splitLine.lineStyle.color = '#e1e1e1'
          vue.option.yAxis.axisLine.lineStyle.color = '#131313'
          vue.option.xAxis.axisLabel.color = '#131313'
          vue.option.yAxis.axisLabel.color = '#131313'
        }
        vue.chart.setOption(this.option)
      },

      reset(data = []) {
        this.option.xAxis.data = []
        let kv = []
        for (let j = 7; j > 0; j--) {
          let t = new Date((new Date().getTime()) - j * 24 * 3600000)
          kv.push({
            value: t.getTime(),
          })
          this.option.xAxis.data.push(t.Format('yyyy-MM-dd'))
        }

        let temp = [
          {
            name: '支付',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: '非支付',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0]
          },
        ]
        if (data.length) {
          let j = 0
          for (let i = 0; i < data.length; i++) {
            let t = new Date()
            t.setFullYear(data[i].year, data[i].month - 1, data[i].day)
            t.setHours(0, 0, 0)
            let ts = t.getTime()
            for (; j < 7; j++) {
              if (kv[j].value > ts) {
                temp[0].data[j] = data[i].payOrders
                temp[1].data[j] = data[i].notPayOrders
                j++
                break
              }
            }
          }
        }
        console.log(this.option)
        this.option.series = temp
        this.chart.setOption(this.option)
      },
      _initCharts(bills) {
        let temp = [
          {
            name: '支付',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0]
          },
          {
            name: '非支付',
            type: 'bar',
            barGap: 0,
            barWidth: '15%',
            data: [0, 0, 0, 0, 0, 0, 0]
          },
        ]
        if (bills.length) {
          for (let i = 0; i < this.bills.length; i++) {
            temp[0].data[i] = bills[i].payOrders
            temp[1].data[i] = bills[i].notPayOrders
          }
        }
        this.option.series = temp
        let vue = this
        if (vue.chart === null) {
          let resizeDiv = document.getElementById('fee-chart')
          this.chart = echarts.init(resizeDiv)
        }
        this.chart.setOption(this.option)
      }
    },
    mounted() {
      let vue = this
      this._initCharts([])
      vue.changeTheme(vue.theme)
      this.$mq.Subcribe(`theme`, function (data) {
        vue.changeTheme(data)
      })

      this.$mq.Subcribe(`resize`, function (data) {
        setTimeout(function () {
          vue.chart.resize()
        }, 200)
      })

    }
  }
</script>
<style lang="scss">
  .statistics-chart {
    width: 100%;
    height: 100%;

    .fee-chart {
      height: 100%;
    }
  }
</style>

