/**
 * Created by fworld on 2019/7/11.
 */
import request from '@/util/request'

export function orderUnion(data) {
    return request({
        url: '/api/v1/collection/order/union',
        method: 'get',
        params: data
    })
}

export function orderExport(data) {
  return request({
      url: '/api/v1/collection/order/export',
      method: 'get',
      params: data
  })
}

export function orderQuery(data) {
  return request({
      url: '/api/v1/collection/order/query',
      method: 'get',
      params: data
  })
}

export function orderList(data) {
    return request({
        url: '/api/v1/collection/order/list',
        method: 'get',
        params: data
    })
}


export function orderPeriodicalUnion(params) {
  return request({
    url:'/api/v1/collection/order/periodical/union',
    method: 'get',
    params
  })
}

export function orderPeriodicalList(params) {
  return request({
    url:'/api/v1/collection/order/periodical/list',
    method: 'get',
    params
  })
}

export function orderSurvey() {
  return request({
    url:'/api/v1/collection/order/survey',
    method: 'get',
  })
}


export function orderRefund(data) {
  return request({
    url:'/api/v1/collection/order/refund',
    method: 'POST',
    data
  })
}
