<template>
  <div class="total-order-chart">
    <div class="chart-pie" id="total-order-chart">

    </div>
  </div>
</template>

<script>
  import echarts from 'echarts'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    computed: {
      ...mapGetters(['theme'])
    },
    components: {},
    mounted: function () {
      this.deviceOrderChart = echarts.init(document.getElementById('total-order-chart'))
      let vue = this
      vue.date = new Date()
      vue.changeTheme(vue.theme)
      this.$mq.Subcribe(`theme`, function (data) {
        vue.changeTheme(data)
      })

      this.chartSelect(this.deviceOrderChart, 0, this.index)
      this.chartEvent(this.deviceOrderChart,this.index)


    },
    data: function () {
      return {
        index: 0,
        deviceOrderOption: {
          color: ["#8d7fec", "#5085f2"],
          legend: {
            top: "6%",
            left: '25%',
            textStyle: {
              color: '#333'
            },
            data: [
              '支付订单',
              '非支付订单'],


            formatter: function (name) {
              return '' + name
            }
          },
          grid: {
            containLabel: true,
          },
          series: {
            type: 'pie',
            clockwise: false, //饼图的扇区是否是顺时针排布
            minAngle: 2, //最小的扇区角度（0 ~ 360）
            radius: ["42%", "66%"],
            center: ["55%", "62%"],
            avoidLabelOverlap: false,
            selectedMode: true,
            itemStyle: { //图形样式
              normal: {
                borderWidth: 6,
              },
            },
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: '{text|{b}}\n{c}\n({d}%)',
                rich: {
                  text: {
                    color: "#666",
                    fontSize: 12,
                    align: 'center',
                    verticalAlign: 'middle',
                    padding: 8
                  },
                  value: {
                    color: "#8693F3",
                    fontSize: 12,
                    align: 'center',
                    verticalAlign: 'middle',
                  },
                }
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: 16,
                }
              }
            },
            data: [
              {name: '支付订单', value: 0},
              {name: '非支付订单', value: 0},
            ]
          }
        },
        deviceOrderChart: null,

      }
    },
    methods: {
      changeTheme(data) {
        let vue = this
        if (data === `dark`) {
          vue.deviceOrderOption.legend.textStyle.color = '#AAAFBA'
          vue.deviceOrderOption.series.label.normal.rich.text.color = '#AAAFBA'
        } else {
          vue.deviceOrderOption.legend.textStyle.color = '#333'
          vue.deviceOrderOption.series.label.normal.rich.text.color = '#333'
        }

        vue.deviceOrderChart.setOption(vue.deviceOrderOption)
      },
      reset(total, today) {
        this.deviceOrderOption.series.data[0].value = total.payOrders + today.payOrders
        this.deviceOrderOption.series.data[1].value = total.notPayOrders + today.notPayOrders
        this.deviceOrderChart.setOption(this.deviceOrderOption)
      },

      chartEvent(char, dataIndex) {
        let vue = this
        char.on('mouseout', function (e) {
          dataIndex = e.dataIndex
          vue.chartSelect(char, 0,  dataIndex)
        })

        char.on('mouseover', function (e) {
          if (dataIndex !== e.dataIndex) {
            char.dispatchAction({
              type: "downplay",
              seriesIndex: 0,

            });
            dataIndex = e.dataIndex
          }
          vue.chartSelect(char, 0,  dataIndex)

        })
      },
      chartSelect (myChart, seriesIndex, dataIndex) {
        myChart.dispatchAction({type: 'highlight', seriesIndex: seriesIndex, dataIndex: dataIndex});
      },
    }
  }
</script>

<style lang="scss">
  .total-order-chart {
    width: 100%;
    height: 100%;

    .chart-pie {
      height: 100%;
    }
  }


</style>
